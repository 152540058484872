@import './src/scss/variables';
@import './src/scss/game';

.widget__game {
	gap: .5rem;
}

.widget__field {
	border: 1px solid;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: fit-content;
    background: $color2;
}

.keyboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wordle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: .5rem;
    //border-top: 1px solid;
    //border-left: 1px solid;

//   &:last-of-type {
//     border-bottom: 1px solid;
// 	}

  &.rtl {
    direction: rtl
  }

  &.wrong {
    .word {
      //border-color: red;
      outline: 2px solid $color11;
    }
  }

  .word {
    //border-right: 1px solid;
    background: $color6;
    width: 67px;
    aspect-ratio: 1 / 1;
    font-size: 28px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;

    &.current {
      //background: #f3f4f7;
      outline: 2px solid #fc0;
    }
  }
}

.key {
  border: 1px solid;
  min-width: 50px;
  height: 50px;
  margin: 2px;
  display: block;
  text-align: center;
  line-height: 45px;
  background: $color2;

  &:hover {
    color: black !important;
    opacity: 1 !important;
    background-color: gray;
  }

  &.in-word {
    background-color: yellow;
    font-weight: bold;
  }

  &.exact {
    background-color: green;
    font-weight: bold;
  }

  &.miss {
    background-color: lightgray;
    font-weight: bold;
  }

}

.key-backspace {
  min-width: 110px;
}

.key-enter {
  min-width: 100px;
}

.finish {
  //color: white;

  .word {
    background-color: $color2;
    color: $color;
  }

  .in-word {
    background-color: $color8;
  }

  .exact {
    background-color: $color9;
  }

}


.hide {
display: none;
}